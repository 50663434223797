<template>
  <div>
    <general-table
      :api-url="apiUrl"
      :edit-content="true"
      :view-content="false"
      :delete-content="false"
      :columns="columns"
      :type="type"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :add-type="addType"
    >
      <template #cell(name_certificate)="data">
        <div
          v-if="data.item.certificate_group"
          style="width: 100%; height: 100%;"
        >
          <span>{{ data.item.certificate_group.name.en }}</span>
          <span v-if="data.item.certificate_group.name.en && data.item.certificate_group.name.ar"> - </span>
          <span>{{ data.item.certificate_group.name.ar }}</span>
        </div>
      </template>
      <template #cell(product_categories)="data">
        <ul
          class="ml-5"
          style="min-width: 28rem;"
        >
          <li
            v-for="item in data.item.product_categories"
            :key="item.id"
            class="text-wrap"
            style="max-width: 28rem; text-align: left;"
          >
            {{ item.name }}
          </li>
        </ul>
      </template>
      <template #cell(name_sup_certificate)="data">
        <div
          v-if="data.item.name"
          style="width: 100%; height: 100%;"
        >
          <span>{{ data.item.name.en }}</span>
          <span v-if="data.item.name.en && data.item.name.ar"> - </span>
          <span>{{ data.item.name.ar }}</span>
        </div>
      </template>
    </general-table>
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'certificates-with-products',
      addType: 'Add Product To Certificate',
      addComponentName: 'add-product-to-certificate',
      editComponent: 'edit-product-to-certificate',
      viewContent: false,
      type: 'page',
      partners: [],
      filter: {},
      types: '',
      columns: [
        // { key: 'id', sortable: true },
        { key: 'name_certificate', label: 'Main Certificate' },
        { key: 'name_sup_certificate', label: 'Sup Certificate' },
        { key: 'product_categories', label: 'Product Categories' },
        { key: 'actions' },
      ],
    }
  },

  computed: {
    apiUrl() {
      let base = 'certificates-with-products?'

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }
      // console.log(base.slice(0, -1))
      return base.slice(0, -1)
    },
  },
  // mounted() {
  //   console.log('Count in mounted:', this.role)
  // },

  methods: {
    filterTypes(type) {
      const filteredType = this.status.filter(item => item.text !== type)
      this.types = filteredType
    },
  },
}
</script>
